<template>
    <div class="map-page">
        <div class="map" ref="MapHolder"></div>
    </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader';
import moment from 'moment';
import { toRaw } from '@vue/reactivity';

    export default {
        components: {

        },
        data() {
            return {
                markers: [],
                mapItem: {
                    map: null
                },
                currentLocation: null
            }
        },
        watch: {
            currentLocation() {
                this.showMarkerOnMap(this.currentLocation.details.position);
                this.zoomTo(this.currentLocation.details.position);
            }
        },
        methods: {
            getCurrentLocation() {
                this.ajax('GetCurrentLocation', {
                    method: 'get',
                    url: `/tracking/track/${this.$route.params.id}`,
                    data: this.item
                }, (err, body) => {
                    if(err) {
                        return;
                    }
                    this.currentLocation = body;
                });
            },
            zoomTo(item) {
                let pos = {
                    lat: item.lat,
                    lng: item.lng,
                }
                this.mapItem.map.setZoom(15);
                this.mapItem.map.panTo(pos); 
            },
            showMarkerOnMap(item) {
                for(const item of this.markers) {
                    toRaw(item).setMap(null);
                }
                this.markers = [];

                let pos = {
                    lat: item.lat,
                    lng: item.lng,
                }
                const marker = new this.mapItem.google.maps.Marker({
                    position: pos,
                    icon: {
                        url: '/images/location-button.png',
                        scaledSize: new this.mapItem.google.maps.Size(20, 20),
                        anchor: new this.mapItem.google.maps.Point(10, 10),
                    },
                    map: this.mapItem.map
                });

                // Add circle overlay and bind to marker
                var circle = new this.mapItem.google.maps.Circle({
                    map: this.mapItem.map,
                    radius: 150,    // 10 miles in metres
                    fillColor: '#007AFF',
                    strokeColor: "#007AFF",
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                });
                circle.bindTo('center', marker, 'position');

                this.mapItem.map.panTo(pos); 
                this.markers.push(marker);
            },
            getDateTime(date) {
                return moment(date).format("MM/DD/YYYY hh:mm A")
            },
            async createAppInstance() {
                
                const loader = new Loader({
                    apiKey: "AIzaSyDLxJWw58qz7U2DCEpEW71sFvQRQH8mURQ",
                    version: "weekly",
                    libraries: ["directions"]
                });

                const mapOptions = {
                    center: {
                        lat: 39.76788904952843,
                        lng:  -102.06309910174043
                    },
                    zoom: 4
                };

                let that = this;
                await loader.load()
                .then((google) => {
                    that.mapItem.google = google;
                    that.mapItem.directionsService = new google.maps.DirectionsService();
                    that.mapItem.directionsRenderer = new google.maps.DirectionsRenderer();
                    that.mapItem.map = new google.maps.Map(this.$refs['MapHolder'], mapOptions);
                    that.mapItem.directionsRenderer.setMap(that.mapItem.map);
                })
                .catch(e => { });

                this.getCurrentLocation();
            }
        },
        mounted() {
            this.createAppInstance();
        }
    }
</script>

<style lang="scss" scoped>
.map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>